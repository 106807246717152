import Vue from 'vue'
import Router from 'vue-router'

import HomePage from '@/components/HomePage.vue' // 主页
import ServicePage from '@/components/ServicePage.vue'
import AboutPage from '@/components/AboutPage.vue'
import RelationshipPage from '@/components/RelationshipPage.vue'
import NewsPage from '@/components/NewsPage.vue'
import DetailPage from '@/components/components/DetailPage.vue'
Vue.use(Router) // 联系我们页面

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: HomePage
    },
    {
      path: '/service',
      name: 'service',
      component: ServicePage
    },
    {
      path: '/about',
      name: 'about',
      component: AboutPage
    },
    {
      path: '/relationship',
      name: 'relationship',
      component: RelationshipPage
    },
    {
      path: '/news',
      name: 'news',
      component: NewsPage
    },
    {
      path: '/detail',
      name: 'detail',
      component: DetailPage
    }
  ]
})
