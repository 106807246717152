import request from '@/utils/ApiReq'
export function getHomeReportList (data) {
  return request({
    url: '/baseFpriceHomeInfo/frontPageList',
    method: 'post',
    data
  })
}
export function getHomeFiancéList (data) {
  return request({
    url: '/baseFpriceHomeFile/reportList',
    method: 'post',
    data
  })
}
export function getDetail (id) {
  return request({
    url: `/baseFpriceHomeInfo/infoDetail?id=${id}`
  })
}
