<template>
  <div class="mobileBottom">
    <div class="contactInformationAll" :class="lang == 'en' ? 'isEn' : ''">
      <div class="bottomLine">
        <ul>
          <li>
            <router-link class="routerLink" to="/service">
              {{ $t('header.service') }}
            </router-link>
          </li>
          <li>
            <router-link class="routerLink" to="/service">
              {{ $t('header.qianxunAPP') }}
            </router-link>
          </li>
          <li>
            <router-link class="routerLink" to="/service">
              {{ $t('header.SaaSFenhao') }}
            </router-link>
          </li>
          <li>
            <router-link class="routerLink" to="/service">
              {{ $t('header.qianxunPremium') }}
            </router-link>
          </li>
        </ul>
          <ul>
            <li>
              <router-link class="routerLink" to="/relationship">
                {{ $t('header.relationship') }}
              </router-link>
            </li>
            <li>
              <router-link class="routerLink" to="/relationship">
                {{ $t('header.financialReport') }}
              </router-link>
            </li>
            <li>
              <router-link class="routerLink" to="/relationship">
                {{ $t('header.companyInfo') }}
              </router-link>
            </li>
            <!--            <li style="font-size: 1rem;margin-bottom: 1.6875rem">{{ $t('header.temporaryNotice') }}</li>-->
            <!--            <li style="font-size: 1rem;">{{ $t('header.regularNotice') }}</li>-->
          </ul>
          <ul>
            <li>
              <router-link class="routerLink" to="/about">
                {{ $t('header.about') }}
              </router-link>
            </li>
            <li>
              <router-link class="routerLink" to="/about">
                {{ $t('header.aboutQianxun') }}
              </router-link>
            </li>
            <li>
              <router-link class="routerLink" to="/about">
                {{ $t('header.developmentHistory') }}
              </router-link>
            </li>
            <!--            <li style="font-size: 1rem;">{{ $t('header.contact') }}</li>-->
          </ul>
        <ul>
          <li>{{ $t('header.contact') }}</li>
          <li>
            {{ $t('header.address') }}
          </li>
        </ul>
      </div>
      <br/>
      <div class="bottom_text">
        {{ $t('header.allRightsReserved') }}
        <a href="https://beian.miit.gov.cn/">粤ICP备 17059071号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileBottom',
  data () {
    return {
      activeNames: ['1'],
      lang: ''
    }
  },
  created () {
    const that = this
    that.$EventBus.$on('refreshfooterlang', () => {
      that.setLang()
    })
  },
  mounted () {
    this.lang = window.localStorage.getItem('language') || 'tw'
  },
  methods: {
    setLang () {
      this.lang = window.localStorage.getItem('language')
    }
  }
}
</script>

<style src="@/assets/style/MobileBottom.scss" lang="scss"></style>
