import { render, staticRenderFns } from "./AboutPage.vue?vue&type=template&id=67818483"
import script from "../assets/js/About.js?vue&type=script&lang=js&external"
export * from "../assets/js/About.js?vue&type=script&lang=js&external"
import style0 from "../assets/style/About.scss?vue&type=style&index=0&prod&lang=scss&external"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports