import MobileBottom from '../../components/components/MobileBottom'
import vuePaginator from 'vue-paginator-simple'
import require from '@/utils/ApiReq'

// '…'

export default {
  name: 'NewsPage',
  components: {
    MobileBottom,
    vuePaginator
  },
  data () {
    return {
      navTagIndex: 0,
      totalPages: 20,
      currentPage: 1,
      pageTags: [],
      initPage: 1,
      curPage: 2,
      pageCount: 20,
      prevPage: 20,
      reqUrl: '',
      list: [{
        id: '',
        infoDateStr: '',
        title: '',
        img: '',
        imgWidth: 0,
        imgHeight: 0,
        detail: '',
        tagList: ['']
      }]
    }
  },
  mounted () {
    const _that = this
    _that.list = []
    _that.getList()
  },
  methods: {
    onNavTagTap (index) {
      const _that = this
      if (this.navTagIndex !== index) {
        _that.navTagIndex = index
        _that.pageNo = 1
        _that.currentPage = 1
        _that.pageTags = []
        _that.totalPages = 0
        _that.totalCount = 0
        _that.list = []
        _that.getList()
      }
    },
    async getList () {
      const _that = this
      console.log(_that.navTagIndex)
      const getParams = {
        businessType: 'new',
        pageNo: _that.currentPage
      }
      if (_that.navTagIndex === 0) {
        getParams.type = 'info'
      } else {
        getParams.type = 'state'
      }
      console.log(getParams)
      try {
        const _data = await require.post('/baseFpriceHomeInfo/frontPageList', getParams)
        for (let i = 0; i < _data.result.records.length; i++) {
          (function (i) {
            _that.list.push({
              id: _data.result.records[i].id || '',
              infoDateStr: _data.result.records[i].infoDateStr || '',
              title: _data.result.records[i].title || '',
              img: _data.result.records[i].img || '',
              detail: _data.result.records[i].detail || '',
              tagList: _data.result.records[i].tagList || ['']
            })
          })(i)
        }
        // for (let i = 0; i < _that.list.length; i++) {
        //   (function (i) {
        //     const _image = new Image()
        //     _image.src = _that.list[i].img
        //     console.log(_image)
        //     // _image.onload = () => {
        //     //   _that.list[i].imgWidth = _image.width
        //     //   _that.list[i].imgHeight = _image.height
        //     // }
        //   })(i)
        // }
        console.log(_that.list)
        _that.currentPage = Number(_data.result.current)
        _that.totalPages = Number(_data.result.pages)
        _that.totalCount = Number(_data.result.total)
        // _that.totalPages = 20
        // _that.totalCount = 180
        if (_that.currentPage < 5) {
          for (let i = 1; i <= 6; i++) {
            if (i <= _that.totalPages) _that.pageTags.push(i)
          }
          if (_that.totalPages > 6) {
            _that.pageTags.push('…')
            _that.pageTags.push(_that.totalPages)
          }
        } else if (_that.currentPage === _that.totalPages) {
          _that.pageTags.push(1)
          _that.pageTags.push('…')
          for (let i = 6; i >= 0; i--) {
            _that.pageTags.push(_that.totalPages - i)
          }
          console.log(_that.pageTags)
        } else {
          let start = 0
          let end = 0
          _that.pageTags = []
          if (_that.totalPages - _that.currentPage <= 4) {
            _that.pageTags.push(1)
            _that.pageTags.push('…')
            end = _that.totalPages
            start = _that.totalPages - 5
            for (let i = start; i <= end; i++) {
              _that.pageTags.push(i)
            }
          } else {
            _that.pageTags.push(1)
            _that.pageTags.push('…')
            end = _that.currentPage + 2
            start = _that.currentPage - 2
            for (let i = start; i <= end; i++) {
              _that.pageTags.push(i)
            }
            _that.pageTags.push('…')
            _that.pageTags.push(_that.totalPages)
          }
        }
        console.log(_that.pageTags)
        console.log(_data)
      } catch (e) {
        console.log(e)
      } finally {
        console.log('ok')
      }
    },
    onNewsDetail (index) {
      const _that = this
      const routeData = _that.$router.resolve({ path: '/detail', query: { id: _that.list[index].id } })
      window.open(routeData.href, '_blank')
      // this.$router.push({
      //   path: '/detail',
      //   query: {
      //     id: _that.list[index].id
      //   }
      // })
    },
    onPagesTap (pageNo) {
      const _that = this
      _that.currentPage = Number(pageNo)
      _that.list = []
      _that.pageTags = []
      _that.getList()
    }
  }
}
