import MobileBottom from '../../components/components/MobileBottom'
import { getHomeFiancéList, getHomeReportList } from '@/api/list'

export default {
  name: 'HomePage',
  components: {
    MobileBottom
  },
  data () {
    return {
      reportList: [],
      financeList: [],
      reportParam: {},
      financeParam: {},
      mouseOverIndex: 1,
      isEn: false,
      showPopup: false,
      swiperOption: {
        direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical
        slidesPerView: 1, // Swiper的列数
        // spaceBetween: 30, //板块间距
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets', // 将分页的类型改为圆点（默认)
          clickable: true // 点击可切换
          // hideOnClick :true, // 点击swiper-slide显隐
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 2秒切换一次
          disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        loop: true, // 无缝轮播
        grabCursor: true // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
      }
    }
  },
  async created () {
    const that = this
    const lang = window.localStorage.getItem('language')
    if (lang === 'en') that.isEn = true
    const rep = await getHomeReportList(that.reportParam)
    that.reportList = rep.result.records
    console.log(rep.result.records)
    const res = await getHomeFiancéList(that.financeParam)
    that.financeList = res.result.finance
    that.$EventBus.$on('refreshhome', () => {
      const lang = window.localStorage.getItem('language')
      if (lang === 'en') that.isEn = true
      else that.isEn = false
    })
  },
  mounted () {
    window.scrollTo({ top: 0 })
  },
  methods: {
    onMouseMoveFun (index) {
      if (index !== this.mouseOverIndex) {
        this.mouseOverIndex = index
      }
    },
    onTurnPageTo () {
      this.$router.push({
        path: '/about'
      })
    },
    onShowDownloadBox () {
      this.showPopup = true
      console.log(111)
      const m = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = 'hidden'
      document.addEventListener('touchmove', m, { passive: false }) // 禁止页面滑动
    },
    onHideDownloadBox () {
      this.showPopup = false
      const m = function (e) {
        e.preventDefault()
      }
      document.body.style.overflow = ''
      document.addEventListener('touchmove', m, { passive: true }) // 禁止页面滑动
    }
  }
}
