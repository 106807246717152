import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    language: 'zh' // 默认语言
  },
  mutations: {
    setLanguage (state, language) {
      state.language = language
    }
  },
  actions: {
    changeLanguage ({ commit }, language) {
      commit('setLanguage', language)
    }
  }
})
