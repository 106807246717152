import MobileBottom from '../../components/components/MobileBottom'

export default {
  name: 'AboutPage',
  components: {
    MobileBottom
  },
  data () {
    return {
      lang: '',
      onHover: false,
      onHoverIndex: 0
    }
  },
  created () {
    const that = this
    that.lang = window.localStorage.getItem('language') || 'tw'
    that.$EventBus.$on('refreshaboutlang', () => {
      window.location.reload()
    })
  },
  mounted () {
    window.scrollTo({ top: 0 })
  },
  methods: {
    test1 (index) {
      const that = this
      that.onHoverIndex = index
      that.onHover = true
      console.log('111')
    },
    test2 () {
      const that = this
      that.onHover = false
      console.log('222')
    }
  }
}
