export default {
  data () {
    return {
      currentLang: 'zh',
      opacity: 0
    }
  },
  components: {
  },
  created () {
    this.currentLang = window.localStorage.getItem('language') || 'tw'
    this.$i18n.locale = this.currentLang
    console.log(this.currentLang)
  },
  mounted () {
    const that = this
    that.currentLang = window.localStorage.getItem('language') || 'tw'
    window.document.onscroll = function (e) {
      // console.log(e.srcElement.defaultView.scrollY)
      if (e.srcElement.defaultView.scrollY <= 0) that.opacity = 0
      else if (e.srcElement.defaultView.scrollY >= 100) that.opacity = 1
      else {
        if (Number(e.srcElement.defaultView.scrollY) / 100 < 1) that.opacity = Number(e.srcElement.defaultView.scrollY) / 100
        else that.opacity = 1
      }
    }
  },
  methods: {
    changeLanguage (language) {
      this.currentLang = language
      this.$i18n.locale = language
      window.localStorage.setItem('language', language)
      // const route = this.$router.history.current.fullPath
      if (this.$route.path === '/detail') {
        this.$router.push('/')
        setTimeout(() => {
          window.location.reload()
        }, 300)
      } else {
        window.location.reload()
        // console.log(this.$route.path)
        // if (this.$route.path === '/about') {
        //   this.$EventBus.$emit('refresh')
        //   this.$EventBus.$emit('refreshfooterlang')
        // } else if (this.$route.path === '/relationship') {
        //
        //   this.$EventBus.$emit('refreshrelationship')
        // } else if (this.$route.path === '/service') {
        //   // window.location.reload()
        //   this.$EventBus.$emit('refreshservicelang')
        // } else {
        //   this.$EventBus.$emit('refreshhome')
        // }
        // window.location.reload()
      }
      // window.scrollTo({ top: 0 })
    }
  }
}
