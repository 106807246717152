import Vue from 'vue'
import VueI18n from 'vue-i18n'
// 引入各个语言配置文件
import zh from './lang/zh.js'
import en from './lang/en.js'
import tw from './lang/tw.js'

Vue.use(VueI18n)

const i18n = new VueI18n({
  // 设置默认语言 todo
  locale: 'zh', // 语言标识
  // 添加多语言（每一个语言标示对应一个语言文件）
  messages: {
    en,
    zh,
    tw
  }
})

export default i18n
