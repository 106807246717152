export default {
  header: {
    tagName: '千循',
    homepage: '首頁',
    service: '産品與服務',
    qianxunAPP: '千循APP',
    SaaSFenhao: '分毫雲SaaS',
    qianxunPremium: '千循優選',
    about: '關于我們',
    aboutQianxun: '關於千循',
    developmentHistory: '發展歷程',
    relationship: '投資者關係',
    companyInfo: '公司概括',
    financialReport: '財務報告',
    temporaryNotice: '臨時公告',
    regularNotice: '定期公告',
    news: '企業新聞',
    qianxunInformation: '千循資訊',
    qianxun: '千循資訊',
    industryTrends: '行業動態',
    industry: '行業動態',
    contact: '聯繫我們',
    address: '公司地址：深圳市福田區梅林街道中康路136號深圳新一代產業園1棟6層',
    allRightsReserved: '版權所有 © 2025 千循科技有限公司',
    seeMore: '查看更多',
    companyName: '千循科技',
    companyReport: '公司公告'
  },
  content: {
    image: {
      banner: require('@/assets/banner_tw.png'),
      aboutUs: require('@/assets/aboutUs_tw.png'),

      homeProductText: require('@/assets/img/home/service_and_product_tw.png'),
      homeQianxunStore: require('@/assets/img/home/qianxun_store_tw.png'),
      homeSaaS: require('@/assets/img/home/SaaS_tw.png'),
      homeGoods: require('@/assets/img/home/goods_tw.png'),
      about: require('@/assets/img/about/aboutqianxun_tw.png'),
      aboutContent: require('@/assets/img/about/aboutIntroduction_tw.png'),
      aboutThree: require('@/assets/img/about/three_tw.png'),
      aboutMap: require('@/assets/img/about/map_tw.png'),
      aboutHistory: require('@/assets/img/about/history_tw.png'),
      about3C: require('@/assets/img/about/3C_tw.png'),
      news: require('@/assets/news_tw.png'),
      relationship: require('@/assets/relationship_tw.png'),
      seeMore: require('@/assets/seeMore_zh.png'),
      phone: require('@/assets/phone_tw.png')
    },
    homepageText: {
      viewDetails: '查看詳情',
      viewMore: '閱讀更多',
      learnMore: '瞭解更多',
      financialReport: '財務報告',
      aboutMe: {
        head: '關於我們',
        headContext: '打造首個開放的二手3C交易供應鏈',
        subText: '在我們的二手電商平台，閑置好物開啓全新旅程，煥發出別樣光彩。平台彙聚了海量的精選商品，其中以二手 3C數碼産品尤爲矚目。從尖端智能手機到高性能電腦，從專業攝影器材到各類智能穿戴設備，豐富的 3C 數碼品類一應俱全，滿足您對科技産品的多元需求。',
        firstItemTitle: '精準估價 高效回收',
        secondItemTitle: '及時匹配 快速去化',
        thirdItemTitle: '無憂交付 樹立標準',
        firstItemTips: 'AI驅動的實時定價系統，完善的在線檢測評估系統。精准估價，打款便捷快速。精准的投流模型，高效觸達，回收機主，有效獲取二手機貨盤。',
        secondItemTips: '只需用戶提出需求，後續選品、議價、下單等繁瑣環節均由我們包辦，節省您的時間與精力。依托平台大數據，實時掌握市場動態，確保採購價格優勢。',
        thirdItemTips: '二手商品品質看得見，現貨陳列，實物細節盡在眼前，您可多角度審視，購物更安心。庫存實時更新，所見即所得，杜絕下單與實物不符的尷尬。'
      }
    },
    relationshipPage: {
      temporaryNotice: '臨時公告',
      regularNotice: '定期公告',
      clickToDownload: '點擊下載'
    }
  },
  newHome: {
    text1: '讓科技永續，讓價值循環',
    text2: '構建全球領先的二手3C循環生態',
    text3: '香港上市企業(股票代碼：1640.HK)',
    text4: '千循科技以AI技術與SaaS平台爲驅動，打造“線上交易+智能管理+線下服務”的全鏈路解決方案，讓每一台二手3C設備煥發新生。',
    text5: '噸',
    text6: '每年減少電子垃圾',
    text7: '設備再利用率',
    text8: '萬噸',
    text9: '二氧化碳減排量',
    text10: '立即估價',
    text11: '爲什麽選擇千循？',
    text12: '72小時，從閑置到價值重生',
    text13: '通過AI智能定價與分毫雲供應鏈網絡，千循實現行業內最快的流轉效率，用戶提交設備後72小時內完成質檢、上架、銷售全流程，平均動銷率提升40%。',
    text14: '立足中國，服務全球',
    text15: '千循設立了香港供應鏈中心、北美邁阿密與中東迪拜運營中心協同布局，覆蓋全球超過40家國際電信運營商和品牌商，爲全球用戶提供更高性價比的二手3C産品。',
    text16: '128項檢測，180天無憂質保，7天無理由退換',
    text17: '每台設備經128項嚴苛檢測，涵蓋硬件、數據、性能等11道工序，品質有保障；180天無優質保，安心使用；7天無理由退換，購物無憂。',
    text18: '千循不錯，比賣給我朋友多賺了500塊!',
    text19: '張先生',
    text20: '北京用戶',
    text21: '庫存周轉從45天縮短到19天。',
    text22: '李女士',
    text23: '某手機數碼工作室',
    text24: '代采功能讓我一鍵比價華強北，采購成本降低8%，還能鎖定區域獨家貨源。',
    text25: '王先生',
    text26: '安徽數碼經銷商',
    text27: '太方便了，以舊換新還能直接去線下千循優選取貨，不用等快遞就是爽。',
    text28: '謝先生',
    text29: '江西用戶',
    text30: '合作夥伴',
    text31: '下載千循APP，獲取更高估價'
  },
  newService: {
    text1: '以AI技術和SaaS平台賦能二手3C交易',
    text2: '千循APP：您的閑置，從這裏開始新的旅程',
    text3: '智能回收',
    text4: 'AI秒級估價，順豐免費上門取件',
    text5: '嚴選商城',
    text6: '128項質檢認證，180天質保，7天無理由退換',
    text7: '以舊換新',
    text8: '舊機最高抵3000元，支持跨品牌置換',
    text9: '舊iPhone 12回收價2150元，加799元換購iPhone 15！',
    text10: '李女士',
    text11: '北京用戶',
    text12: '用千循賣掉5D4，比本地二手商多賺2200！AI驗機功能太實用了，鏡頭微痕都拍得清清楚楚。',
    text13: '張先生',
    text14: '杭州用戶',
    text15: '通過APP批量代采翻新機，采購價比華強北批發市場還低8%，質保期延長到180天。',
    text16: '王先生',
    text17: '成都數碼店主',
    text18: '以舊換新計劃太棒了！舊華爲Mate40抵扣了2850元，新機分期免息，月供才399元。',
    text19: '王先生',
    text20: '深圳用戶',
    text21: '分毫雲SaaS：二手3C商家的智能生意管家',
    text22: 'AI動銷助手',
    text23: '實時監控各渠道價格波動，自動生成調價策略，提升動銷確保利潤率。',
    text24: '供應鏈管家',
    text25: '集成ERP智能管理和全渠道銷售中台，自動化訂單處理、多倉智能調撥、動態庫存預測。',
    text26: '全渠道同售',
    text27: '接入主流銷售平台並支持三合一小程序，一鍵拓展線上銷售渠道。',
    text28: '接入分毫雲後，滯銷庫存從37%降到9%，動銷率提升至行業TOP5%，月均GMV突破100萬。',
    text29: '宋先生',
    text30: '深圳某數碼工作室',
    text31: '利用AI調價策略，iPhone系列利潤率提高11%，庫存周轉天數從38天壓縮到15天。',
    text32: '付先生',
    text33: '武漢手機賣家',
    text34: '三合一小程序直接對接直播間，上新速度提升3倍，客訴率下降65%。',
    text35: '王女士',
    text36: '杭州二手機直播賣家',
    text37: '質量追溯系統幫我們定位到批次電池問題，避免潛在售後損失超80萬元。',
    text38: '夏先生',
    text39: '成都維修服務商',
    text40: '千循優選運營中心：二手3C標准化體驗中心',
    text41: '全面的售後保障與專業認證',
    text42: '確保客戶購機無憂，提供7天無理由退換貨、180天質保和終身技術支持，同時提供詳細的檢測報告。',
    text43: '優化的交易流程與資源整合',
    text44: '通過整合區域二手貨源和搭建B端交易平台，提升交易透明度與信任度，同時降低采購成本。',
    text45: '靈活的購買方案與透明定價',
    text46: '提供以舊換新服務和實時市場化價格，激發購買意願並保障購買安心。',
    text47: '豐富的商品選擇與快速交付',
    text48: '提供近千款SKU，涵蓋多種類型，並確保快速面對面履約，履約時效領先同行。',
    text49: '千循科技(1640.HK)作爲領先的二手數碼交易服務商，致力于以AI技術和SaaS平台賦能二手數碼交易，滿足消費降級周期下的巨大市場需求。公司通過線上平台回收、渠道自助機回收、自營門店回收等多種方式，實現二手3C産品的高效回收。同時，借助AI技術和SaaS平台，爲二手數碼交易提供精准報價、智能同售、庫存管理等一站式服務，提升交易效率與利潤空間。',
    text50: '千循科技憑借其創新的業務模式和強大的技術實力，已在香港、深圳、邁阿密、迪拜等地設立運營中心，形成了全球化的業務布局。公司旗下千循APP擁有超過1000萬注冊用戶，日均訂單量超1000 ，成爲用戶買賣二手數碼産品的首選平台。此外，千循優選城市運營中心的建立，進一步整合了區域二手貨盤，打破了信息孤島，提升了商品流轉效率。',
    text51: '展望未來，千循科技將繼續加大在技術研發、市場拓展、品牌建設等方面的投入，進一步鞏固其在二手數碼交易領域的領先地位，爲股東創造更大的價值。',
    text52: '千循科技(1640.HK)作爲領先的二手數碼交易服務商，致力于以AI技術和SaaS平台賦能二手數碼交易，滿足消費降級周期下的巨大市場需求。',
    text53: '公司通過線上平台回收、渠道自助機回收、自營門店回收等多種方式，實現二手3C産品的高效回收。',
    text54: '同時，借助AI技術和SaaS平台，爲二手數碼交易提供精准報價、智能同售、庫存管理等一站式服務，提升交易效率與利潤空間。',
    text55: '以AI技術和SaaS平台賦能',
    text56: '二手3C交易'
  },
  newAboutMe: {
    text1: '關于千循',
    text2: '千循科技致力于構建全場景數碼産品循環生態，通過線上平台+SaaS工具+線下服務三位一體的解決方案，爲用戶提供便捷、可靠的二手3C産品交易及管理服務，推動綠色循環經濟發展。',
    text3: '核心業務布局',
    text4: '數字交易平台',
    text5: '千循APP集成商城、代采、回收三大功能，專注手機及周邊數碼産品流通。平台憑借智能推薦系統與精准搜索技術，讓用戶輕松實現閑置變現與優質購機體驗。',
    text6: '智慧SaaS系統',
    text7: '分毫雲爲企業客戶提供數字化管理解決方案，集成ERP智能管理、三合一小程序、全渠道銷售中台等功能。系統支持自動化訂單處理、多倉智能調撥、動態庫存預測及全鏈路質量追溯，助力企業降本增效。',
    text8: '實體服務網絡',
    text9: '千循優選運營中心遍布全國核心城市，打造檢測-回收-銷售-服務四位一體實體站點，提供專業設備回收、官方認證産品銷售、售後維修及以舊換新等一站式服務。',
    text10: '品質保障體系',
    text11: '128項嚴苛質檢',
    text12: '每件3C産品均需通過128項嚴苛檢測，涵蓋硬件、性能、數據清理等11道工序，由百人專業質檢團隊執行，確保設備達到准新機標准。',
    text13: '180天無憂質保',
    text14: '提供180天無憂質保服務，質保期內設備出現故障，我們將提供免費維修或更換，售後團隊快速響應，保障您的使用體驗。',
    text15: '7天無理由退換',
    text16: '支持7天無理由退換服務，不滿意可隨時申請，客服團隊全程協助，確保退換流程便捷高效，讓您購物無後顧之憂。',
    text17: '可持續未來的承諾',
    text18: '作爲循環經濟踐行者，我們已實現：',
    text19: '【減少電子垃圾4618噸】 【産品再利用率達92.3%】 【二氧化碳減排量210萬噸】',
    text20: '通過技術創新與服務升級，我們持續推動讓每台設備完成至少3次生命周期的願景，爲用戶創造價值的同時守護綠色地球。',
    text21: '公司成立',
    text22: '3C報價服務平台“分毫報價”上線',
    text23: 'APP上線',
    text24: '千循APP上線',
    text25: '推出分毫雲SaaS',
    text26: '行業領先的3C全品類回收估價系統',
    text27: '短視頻平台流量矩陣搭建',
    text28: 'APP用戶量突破1000萬+',
    text29: '内容矩陣粉絲2000萬+',
    text30: '曝光覆蓋人數1億+',
    text31: 'APP領跑二手賽道',
    text32: '平台618大促二手數碼交易量全網前三',
    text33: '完成香港上市重組',
    text34: '港股“循環經濟"第一股',
    text35: '公司發展餍程'
  }
}
