import MobileBottom from '../../components/components/MobileBottom'
import require from '../../utils/ApiReq'
import { saveAs } from 'file-saver'
import axios from 'axios'

let winInterval // 定时

export default {
  name: 'RelationshipPage',
  components: {
    MobileBottom
  },
  data () {
    return {
      navIndex: 0,
      finance: [{
        fileName: '',
        fileType: '',
        fileUrl: '',
        infoDateStr: '',
        title: ''
      }],
      notice: [{
        fileName: '',
        fileType: '',
        fileUrl: '',
        infoDateStr: '',
        title: ''
      }],
      swiperOption: {
        direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical
        slidesPerView: 1, // Swiper的列数
        // spaceBetween: 30, //板块间距
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets', // 将分页的类型改为圆点（默认)
          clickable: false // 点击可切换
          // hideOnClick :true, // 点击swiper-slide显隐
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        // autoplay: {
        //   delay: 3000, // 2秒切换一次
        //   disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        // },
        loop: false, // 无缝轮播
        grabCursor: true // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
      },
      data: {
        zh: {
          part1: '千循科技(1640.HK)作为领先的二手数码交易服务商，致力于以AI技术和SaaS平台赋能二手数码交易，满足消费降级周期下的巨大市场需求。公司通过线上平台回收、渠道自助机回收、自营门店回收等多种方式，实现二手3C产品的高效回收。同时，借助AI技术和SaaS平台，为二手数码交易提供精准报价、智能同售、库存管理等一站式服务，提升交易效率与利润空间。',
          part2: '千循科技凭借其创新的业务模式和强大的技术实力，已在香港、深圳、迈阿密、迪拜等地设立运营中心，形成了全球化的业务布局。公司旗下千循APP拥有超过1000万注册用户，日均订单量超1000+，成为用户买卖二手数码产品的首选平台。此外，千循优品城市运营中心的建立，进一步整合了区域二手货盘，打破了信息孤岛，提升了商品流转效率。',
          part3: '展望未来，千循科技将继续加大在技术研发、市场拓展、品牌建设等方面的投入，进一步巩固其在二手数码交易领域的领先地位，为股东创造更大的价值。'
        },
        tw: {
          part1: '千循科技(1640.HK)作爲領先的二手數碼交易服務商，致力于以AI技術和SaaS平台賦能二手數碼交易，滿足消費降級周期下的巨大市場需求。公司通過線上平台回收、渠道自助機回收、自營門店回收等多種方式，實現二手3C産品的高效回收。同時，借助AI技術和SaaS平台，爲二手數碼交易提供精准報價、智能同售、庫存管理等一站式服務，提升交易效率與利潤空間。',
          part2: '千循科技憑借其創新的業務模式和強大的技術實力，已在香港、深圳、邁阿密、迪拜等地設立運營中心，形成了全球化的業務布局。公司旗下千循APP擁有超過1000萬注冊用戶，日均訂單量超1000 ，成爲用戶買賣二手數碼産品的首選平台。此外，千循優品城市運營中心的建立，進一步整合了區域二手貨盤，打破了信息孤島，提升了商品流轉效率。',
          part3: '展望未來，千循科技將繼續加大在技術研發、市場拓展、品牌建設等方面的投入，進一步鞏固其在二手數碼交易領域的領先地位，爲股東創造更大的價值。'
        },
        en: {
          part1: 'Qianxun Technology (1640.HK), a leading service provider in the pre-owned digital device trading sector, is dedicated to empowering secondhand electronics transactions through AI technology and SaaS platforms, addressing surging market demand amid value-conscious consumption trends.The company achieves high-efficiency recycling of pre-owned 3C products via multiple channels, including its online platform, self-service kiosks at partner locations, and company-owned retail stores.Leveraging AI and SaaS capabilities, it delivers end-to-end solutions such as precision pricing, intelligent omnichannel distribution, and inventory optimization, enhancing transaction efficiency and profitability margins across the ecosystem.',
          part2: 'Qianxun Technology has established operational centers in Hong Kong, Shenzhen, Miami, and Dubai through its innovative business model and strong technological capabilities, forming a global business network.The company’s Qianxun APP boasts over 10 million registered users and processes 1,000+ daily orders, solidifying its position as the go-to platform for trading pre-owned digital devices.Furthermore, the launch of Qianxun Premium Hub city operational centers has consolidated regional pre-owned inventories, eliminated information silos, and significantly improved product circulation efficiency.',
          part3: 'Looking ahead, Qianxun Technology will continue to ramp up investments in technology R&D, market expansion, and brand building to further solidify its leadership in the pre-owned digital devices trading sector, thereby creating greater value for shareholders.'
        }
      },
      lang: '',
      onHover: false,
      onHoverIndex: 0,
      quoteStockList: [{
        code: '', // 代码
        name: '', // 名称
        price: 0, // 最新价格
        upDownPriceStr: '', // 涨跌额
        upDownLimitStr: '', // 涨跌幅
        upDownPrice: 0, // 涨跌额
        upDownLimit: 0, // 涨跌幅
        firstPrice: 0, // 今日开盘价
        maxPrice: 0, // 最高价
        minPrice: 0, // 最低价
        yesterdayPrice: 0, // 昨日收入
        turnover: 0, // 成交量
        turnoverPrice: 0, // 成交额
        create_time: '', // 时间
        createTimeStr: '', // 格式化时间
        upDownFlag: '' // 涨跌表示:up涨 down跌 equality相等
      }]
    }
  },
  created () {
    const that = this
    that.lang = window.localStorage.getItem('language') || 'tw'
    that.$EventBus.$on('refreshrelationship', () => {
      // that.swiper.loopDestroy()
      // that.swiper.loopCreate()
      // swiper.init(that.swiperOption)
      // that.lang = window.localStorage.getItem('language') || 'tw'
      window.location.reload()
    })
  },
  async mounted () {
    const _that = this
    _that.finance = []
    _that.notice = []
    window.scrollTo({ top: 0 })
    // 定时获取
    _that.getQuoteStock()
    winInterval = window.setInterval(() => {
      _that.getQuoteStock()
    }, 1000 * 10)
    // eslint-disable-next-line no-unused-expressions
    winInterval
    _that.getList()
  },
  unmounted () {
    // 清定时
    window.clearInterval(winInterval)
  },
  methods: {
    onNavTap (index) {
      const _that = this
      if (_that.navIndex !== index) _that.navIndex = index
      _that.getList1()
    },
    async getQuoteStock () {
      const _that = this
      // console.log(111)
      try {
        const _data = await require.get('/baseFpriceHomeStock/findFirstStorkList')
        _that.quoteStockList = []
        _that.quoteStockList = _that.quoteStockList.concat(_data.result[_data.result.length - 1])
      } catch (e) {
        console.log(e)
      } finally {
        // console.log('ok')
      }
    },
    async getList () {
      const _that = this
      const params = {
        classify: _that.navIndex === 0 ? 'zero' : 'fix',
        financeNo: 4
      }
      try {
        const _data = await require.post('/baseFpriceHomeFile/reportList', params)
        _that.finance = []
        _that.notice = []
        _that.finance = _that.finance.concat(_data.result.finance)
        // _that.notice = _that.notice.concat(_data.result.notice)
        let noticeCount = 0
        if (_data.result.notice.length > 9) {
          noticeCount = 9
        } else {
          noticeCount = _data.result.notice.length
        }
        for (let i = 0; i < noticeCount; i++) {
          _that.notice = _that.notice.concat([_data.result.notice[i]])
        }
      } catch (e) {
        console.log(e)
      } finally {
        // console.log('ok')
      }
    },
    async getList1 () {
      const _that = this
      const params = {
        classify: _that.navIndex === 0 ? 'zero' : 'fix',
        financeNo: 4
      }
      try {
        const _data = await require.post('/baseFpriceHomeFile/reportList', params)
        // _that.notice = _that.notice.concat(_data.result.notice)
        let noticeCount = 0
        if (_data.result.notice.length > 9) {
          noticeCount = 9
        } else {
          noticeCount = _data.result.notice.length
        }
        _that.notice = []
        for (let i = 0; i < noticeCount; i++) {
          _that.notice = _that.notice.concat([_data.result.notice[i]])
        }
      } catch (e) {
        console.log(e)
      } finally {
        // console.log('ok')
      }
    },
    async onDownloadTap (fileUrl, fileName, fileType) {
      const downloadUrl = fileUrl
      let _contentType = ''
      let _saveName = ''
      if (fileType === 'image') {
        _contentType = 'application/image'
        _saveName = fileName + '.jpg'
      } else if (fileType === 'pdf') {
        _contentType = 'application/pdf'
        _saveName = fileName
      } else {
        _contentType = 'application/excel'
        _saveName = fileName + '.excel'
      }

      await axios.get(downloadUrl, {
        'content-type': _contentType,
        responseType: 'blob'
      }).then(response => {
        console.log(response.data)
        saveAs(response.data, _saveName)
      })
    },
    test1 (index) {
      const that = this
      that.onHoverIndex = index
      that.onHover = true
    },
    test2 () {
      const that = this
      that.onHover = false
    }
  }
}
