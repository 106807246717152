export default {
  header: {
    tagName: 'QianXun',
    homepage: 'Home Page',
    service: 'Product & Service',
    qianxunAPP: 'Qianxun APP',
    qianxunPremium: 'Qianxun Youxuan',
    SaaSFenhao: 'Fenhao Cloud SaaS',
    about: 'About Us',
    aboutQianxun: 'About Qianxun',
    developmentHistory: 'Development History',
    relationship: 'Investor Relations',
    companyInfo: 'Company Overview',
    financialReport: 'Financial Report',
    temporaryNotice: 'Temporary Notice',
    regularNotice: 'Regular Notice',
    news: 'Enterprise News',
    qianxunInformation: 'Qianxun Information',
    qianxun: 'News',
    industryTrends: 'Industry Trends',
    industry: 'Industry',
    contact: 'Contact Us',
    address: 'Company Address: 6th Floor, Building 1, Shenzhen New Generation Industrial Park, No. 136 Zhongkang Road, Meilin Street, Futian District, Shenzhen',
    allRightsReserved: 'Copyright © 2025 Qianxun Technology Co., Ltd. All rights reserved',
    seeMore: 'See More',
    companyName: 'QianXun',
    companyReport: 'Company Report'
  },
  content: {
    image: {
      banner: require('@/assets/banner_en.png'),
      aboutUs: require('@/assets/aboutUs_en.png'),

      homeProductText: require('@/assets/img/home/service_and_product_en.png'),
      homeQianxunStore: require('@/assets/img/home/qianxun_store_en.png'),
      homeSaaS: require('@/assets/img/home/SaaS_en.png'),
      homeGoods: require('@/assets/img/home/goods_en.png'),
      about: require('@/assets/img/about/aboutqianxun_en.png'),
      aboutContent: require('@/assets/img/about/aboutIntroduction_en.png'),
      aboutThree: require('@/assets/img/about/three_en.png'),
      aboutMap: require('@/assets/img/about/map_en.png'),
      aboutHistory: require('@/assets/img/about/history_en.png'),
      about3C: require('@/assets/img/about/3C_en.png'),
      news: require('@/assets/news_en.png'),
      relationship: require('@/assets/relationship_en.png'),
      seeMore: require('@/assets/seeMore_en.png'),
      phone: require('@/assets/phone_en.png')
    },
    homepageText: {
      viewDetails: 'View Details',
      viewMore: 'View More',
      learnMore: 'Learn More',
      financialReport: 'Financial Report',
      aboutMe: {
        head: 'About Me',
        headContext: 'Create the first open second-hand 3C transaction supply chain',
        subText: 'On our second-hand e-commerce platform, idle good things start a new journey and shine with a different kind of brilliance. The platform brings together a large number of selected products, among which second-hand 3C digital products are particularly eye-catching. From cutting-edge smartphones to high-performance computers, from professional photography equipment to various smart wearable devices, a wide range of 3C digital categories are available to meet your diverse needs for technology products.',
        firstItemTitle: 'Accurate valuation and efficient recycling',
        secondItemTitle: 'Timely matching and quick removal',
        thirdItemTitle: 'Worry-free delivery sets the standard',
        firstItemTips: 'AI-driven real-time pricing system and complete online detection and evaluation system. Accurate valuation, convenient and fast payment.Accurate delivery model, efficient access, recycling of machine owners, and effective acquisition of used mobile phone pallets.',
        secondItemTips: 'Users only need to put forward their requirements, and we will take care of all the tedious steps such as product selection, price negotiation, and order placement, saving you time and energy. Relying on the platform\'s big data, we can keep abreast of market trends in real time to ensure an advantage in purchasing prices.',
        thirdItemTips: 'The quality of second - hand goods is tangible. With spot goods on display, every detail of the actual items is right before your eyes. You can examine them from multiple perspectives, bringing you a more reassuring shopping experience. The inventory is updated in real - time, ensuring that what you see is what you get, and eliminating the awkward situation where the ordered item differs from the actual one.'
      }
    },
    relationshipPage: {
      temporaryNotice: 'Temporary Notice',
      regularNotice: 'Regular Notice',
      clickToDownload: 'Download'
    }
  },
  newHome: {
    text1: 'Sustaining Technology, Circulating Value',
    text2: 'Building a Global Leading Ecosystem for Pre-owned 3C Circularity',
    text3: 'Hong Kong Stock Exchange Listed (Ticker: 1640.HK)',
    text4: 'Powered by AI and SaaS platforms, Qianxun Technology delivers end-to-end integrated solutions spanning online transactions, intelligent management, and offline services, enabling every pre-owned 3C device to embark on a new lifecycle.',
    text5: 'TONS',
    text6: 'Annual e-waste reduction.',
    text7: 'Equipment rejuvenation rate.',
    text8: 'MT',
    text9: 'CO₂ emission reduction.',
    text10: 'Instant Valuation Now',
    text11: 'Why Qianxun? The Circular Tech Advantage?',
    text12: '72-Hour Value Revival Cycle.',
    text13: 'Through AI-powered intelligent pricing and the Fenhao Cloud supply chain network, Qianxun achieves the industry\'s fastest turnaround efficiency. The entire process—quality inspection, listing, and sales—is completed within 72 hours after device submission, driving a 40% average improvement in inventory turnover rate.',
    text14: 'Global Reach, Local Expertise.',
    text15: 'Through our Hong Kong Global Supply Chain Center complemented by regional hubs in Miami and Dubai, Qianxun\'s Certified Refurbished Network connects 40 international telecom operators and OEM partners, delivering premium recertified 3C devices to consumers worldwide.',
    text16: '128-Point Certification, 180-Day Warranty, 7-Day Returns.',
    text17: 'Every device completes an 11-stage recertification process with 128 diagnostic checks covering hardware integrity, data sanitization, and performance benchmarking.All units come with 180-day technical support and hassle-free returns within 7 days.',
    text18: 'Sold my device on Qianxun for ¥500 more than private sales!',
    text19: 'Mr.Zhang',
    text20: 'Beijing',
    text21: 'Inventory turnover slashed from 45 to 19 days.',
    text22: 'Li\'s',
    text23: 'Mobile Tech Workshop',
    text24: 'Qianxun Sourcing Agent lets me benchmark Huaqiangbei Market prices in one click, cutting procurement costs by 8% with regional exclusivity',
    text25: 'Mr.Wang',
    text26: 'Anhui 3C Distributor',
    text27: 'Instant trade-ins with pickup at Qianxun Youxuan\'s Operations – no delivery wait!',
    text28: 'Mr Xie',
    text29: 'Jiangxi User.',
    text30: 'Trusted Partners',
    text31: 'Download Qianxun',
    text32: 'Why Qianxun?',
    text33: 'The Circular Tech Advantage?'
  },
  newService: {
    text1: 'Powering pre-owned 3C trade with AI & SaaS',
    text2: 'Qianxun: Where Tech Begins Again.',
    text3: 'Intelligent Recycling',
    text4: 'Instant AI Valuation，Free SF Express Pickup',
    text5: 'Selected Mall',
    text6: '128-Point Certification, 180-Day Warranty, 7-Day Returns.',
    text7: 'Cross-Upgrade Program',
    text8: 'Up to ¥3,000 Credit， Any Brand Eligible',
    text9: 'Got ¥2,150 for my iPhone 12,add ¥799 upgrades to iPhone 15!',
    text10: 'Ms. Li',
    text11: 'Beijing',
    text12: 'Sold my Canon 5D4 on Qianxun for ¥2,200 vs local dealers! Their AI Detection even spotted lens micro-scratches I missed.',
    text13: 'Mr.Zhang',
    text14: 'Hangzhou',
    text15: 'Qianxun B2B Sourcing 8% below Huaqiangbei wholesale pricing with 180-day warranty.',
    text16: 'Mr.Wang\'s',
    text17: 'Chengdu Tech Store.',
    text18: 'Traded Huawei Mate40 for ¥2,850 credit! New device with 0% APR financing at ¥399/month.',
    text19: 'Mr.Wang',
    text20: 'Shenzhen',
    text21: 'Fenhao Cloud SaaS: The Intelligent Business Steward for Pre-owned 3C Merchants',
    text22: 'AI-Powered Sales Velocity Optimizer',
    text23: 'Dynamic cross-platform price monitoring, auto-repricing engine to boost sales, and protect your profits.',
    text24: 'SmartChain Orchestrator',
    text25: 'Seamlessly connects smart ERP systems to omnichannel sales hubs, automating orders, optimizing warehouse transfers, and predicting inventory needs in real-time.',
    text26: 'Unified Channel Convergence',
    text27: 'Connect to major sales platforms and deploy an all-in-one mini app—scale your online sales with a single click.',
    text28: 'Fenhao Cloud integration slashed dead stock from 37% to 9%, pushing our sales velocity into the industry\'s top 5% with ¥1million monthly GMV.',
    text29: 'Mr.Song\'s',
    text30: 'Shenzhen Tech Hub .',
    text31: 'AI Repricer boosted iPhone margins by 11% while cutting inventory days from 38 to 15.',
    text32: 'Mr.Fu',
    text33: 'Wuhan Premium Reseller',
    text34: 'TriplePlay MiniApp integration with live commerce accelerated SKU listing by three times, dropping CSAT cases by 65%.',
    text35: 'Ms.Wang',
    text36: 'Hangzhou Livestream Pro',
    text37: 'Batch defect tracking via qianxun\'s QTS prevented ¥800K in warranty costs.',
    text38: 'Mr.Xia\'s',
    text39: 'Chengdu Service Network',
    text40: 'Qianxun Youxuan\'s Operation Centers- Certified Refurbished Tech Ecosystem',
    text41: 'After-Sales Protection & Certification',
    text42: 'Worry-Free Purchase 7-Day No-reason Return 180-Day Warranty Lifetime Technical Support',
    text43: 'Transaction Process & Resource Integration',
    text44: 'Goods Sourcing Integration B2B Platform Construction Transparent Transactions Lower Procurement Costs',
    text45: 'Purchase Plans & Transparent Pricing',
    text46: 'Trade-in Programs Real-time Market Prices Transaction Protection',
    text47: 'Diverse Products & Rapid Delivery',
    text48: 'Thousand SKUs Rapid Face-To-Face Trading Leading Fulfillment Speed',
    text49: 'Qianxun Technology (1640.HK), a Leading service provider in the pre-owned digital device trading sector, is dedicated to empowering secondhand electronics transactions through AI technology and SaaS platforms, addressing surging market demand amid value-conscious consumption trends.The company achieves high-efficiency recycling of pre-owned 3C products via multiple channels, including its online platform, self-service kiosks at partner locations, and company-owned retail stores.Leveraging AI and SaaS capabilities, it delivers end-to-end solutions such as precision pricing, intelligent omnichannel distribution, and inventory optimization, enhancing transaction efficiency and profitability margins across the ecosystem.',
    text50: 'Qianxun Technology has established operational centers in Hong Kong, Shenzhen, Miami, and Dubai through its innovative business model and strong technological capabilities, forming a global business network.The company’s Qianxun APP boasts over 10 million registered users and processes 1,000+ daily orders, solidifying its position as the go-to platform for trading pre-owned digital devices.Furthermore, the launch of Qianxun Youxuan\'s operation centers has consolidated regional pre-owned inventories, eliminated information silos, and significantly improved product circulation efficiency.',
    text51: 'Looking ahead, Qianxun Technology will continue to ramp up investments in technology R&D, market expansion, and brand building to further solidify its leadership in the pre-owned digital devices trading sector, thereby creating greater value for shareholders.',
    text52: 'Qianxun Technology (1640.HK), a Leading service provider in the pre-owned digital device trading sector, is dedicated to empowering secondhand electronics transactions through AI technology and SaaS platforms, addressing surging market demand amid value-conscious consumption trends.',
    text53: 'The company achieves high-efficiency recycling of pre-owned 3C products via multiple channels, including its online platform, self-service kiosks at partner locations, and company-owned retail stores.',
    text54: 'Leveraging AI and SaaS capabilities, it delivers end-to-end solutions such as precision pricing, intelligent omnichannel distribution, and inventory optimization, enhancing transaction efficiency and profitability margins across the ecosystem.',
    text55: 'Worry-Free Purchase',
    text56: '7-Day No-reason Return',
    text57: '180-Day Warranty',
    text58: 'Lifetime Technical Support',
    text59: 'Goods Sourcing Integration',
    text60: 'B2B Platform Construction',
    text61: 'Transparent Transactions',
    text62: 'Lower Procurement Costs',
    text63: 'Trade-in Programs',
    text64: 'Real-time Market Prices',
    text65: 'Transaction Protection',
    text66: 'Thousand SKUs',
    text67: 'Rapid Face-To-Face Trading',
    text68: 'Leading Fulfillment Speed',
    text69: 'Instant AI Valuation',
    text70: 'Free SF Express Pickup',
    text71: '128-Point Certification',
    text72: '180-Day Warranty',
    text73: '7-Day Returns',
    text74: 'Up to ¥3,000 Credit',
    text75: 'Any Brand Eligible',
    text76: 'Fenhao Cloud SaaS: The Intelligent Business',
    text77: 'Steward for Pre-owned 3C Merchants'
  },
  newAboutMe: {
    text1: 'About Qianxun',
    text2: 'Qianxun Technology is dedicated to building a full-scenario circular ecosystem for digital devices. Through its trinity solution integrating online platforms, SaaS tools, and offline services, the company delivers seamless and trustworthy transaction and management services for pre-owned 3C products, driving the advancement of green circular economy practices.',
    text3: 'Core Business Framework',
    text4: 'Digital Trading Platform',
    text5: 'The Qianxun App integrates three major functions—mall, agency purchasing, and recycling—specializing in the circulation of smartphones and peripheral digital products. Leveraging an intelligent recommendation system and precise search technology, the platform empowers users to effortlessly monetize idle items and enjoy premium smartphone purchasing experiences.',
    text6: 'Smart SaaS System',
    text7: 'Fenhao Cloud provides enterprise clients with digital management solutions that integrate intelligent ERP systems, a three-in-one mini-program, and an omnichannel sales platform. The system supports automated order processing, smart multi-warehouse allocation, dynamic inventory forecasting, and end-to-end quality traceability, empowering businesses to reduce costs and enhance operational efficiency.',
    text8: 'Physical Service Network',
    text9: 'The Qianxun Youxuan\'s operation centers are located in core cities nationwide, establishing four-in-one physical sites integrating inspection, recycling, sales, and services. These centers provide comprehensive services including professional equipment recycling, sales of officially certified products, after-sales maintenance, and trade-in services.',
    text10: 'Quality Assurance System',
    text11: '128-Point Certification',
    text12: 'Each 3C product must undergo 128 rigorous inspections covering 11 procedures including hardware, performance, and data cleaning, executed by a professional 100-member quality inspection team to ensure devices meet quasi-new machine standards.',
    text13: '180-Day Warranty',
    text14: 'The Qianxun App offers a 180-day worry-free warranty service. During the warranty period, any device malfunctions will be addressed with free repairs or replacements, supported by our responsive after-sales team to ensure uninterrupted usage experiences.',
    text15: '7-Day Returns',
    text16: 'The service supports 7-day no-reason return. Dissatisfied customers may initiate requests at any time, with the customer service team providing end-to-end assistance to ensure convenient and efficient processes, delivering worry-free shopping experiences.',
    text17: 'Commitment to a Sustainable Future',
    text18: 'As a practitioner of the circular economy, we have achieved:',
    text19: '【4,618 tons of e-waste reduced】【92.3% product reuse rate】【2.1 million tons of CO₂ emissions reduced】',
    text20: 'Through technological innovation and service upgrades, we persistently drive the vision of enabling each device to complete at least three life cycles, creating value for users while safeguarding the green planet.',
    text21: 'Business formation',
    text22: '3C quotation service platform The \'Fen Hao Quotation\' has been launched',
    text23: 'APP launched',
    text24: 'Qianxun APP launched',
    text25: 'Launch Fenhao Cloud SaaS',
    text26: 'Industry leading 3c full category recycling valuation system',
    text27: 'Building a traffic matrix for short video platforms',
    text28: 'The number of App users has exceeded 10 million+',
    text29: 'Content matrix with 20 million followers+',
    text30: 'Exposure coverage of 100 million people+',
    text31: 'APP led the second-hand track',
    text32: 'Second-hand digital products transaction volume ranked top 3 during June 18 shopping carnival',
    text33: 'Complete Hong Kong listing and restructuring',
    text34: 'The first stock of "circular economy ”in Hong Kong stock market',
    text35: 'Development History'
  }
}
