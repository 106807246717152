<template>
  <div class="service">
    <div class="background-image">
      <!--      <router-link to="/service">-->
      <!--        <div class="detail" style="position: absolute;top: 70%;left: 8%;">-->
      <!--          <p>{{ $t('content.homepageText.viewDetails') }}</p>-->
      <!--          <img src="~@/assets/arrow.png" alt="" class="detail-img">-->
      <!--        </div>-->
      <!--      </router-link>-->
      <div class="background-text" v-if="lang == 'en'">
        {{ $t('newService.text1') }}
      </div>
      <div class="background-text" v-else>
        {{ $t('newService.text55') }}<br>
        {{ $t('newService.text56') }}
      </div>
    </div>
    <div class="content-box">

        <div class="firstBox">
          <div class="box-title">
            {{ $t('newService.text2') }}
          </div>
          <div class="qianxun">
            <div style="justify-content: space-evenly;display: flex;">
              <div class="qianxun-item">
                <div class="item-img">
                  <div class="item-img__fixedBox">
                    <div class="item-img__fixedBoxText" :class="[lang == 'en' ? 'isEn2' : '']">
                      {{ $t('newService.text3') }}
                    </div>
                  </div>
                </div>
                <div class="item-text" v-if="lang != 'en'">
                  {{ $t('newService.text4') }}
                </div>
                <div class="item-text" v-else>
                  {{ $t('newService.text69') }}<br>
                  {{ $t('newService.text70') }}
                </div>
              </div>
              <div class="qianxun-item">
                <div class="item-img img2">
                  <div class="item-img__fixedBox">
                    <div class="item-img__fixedBoxText" :class="[lang == 'en' ? 'isEn' : '']">
                      {{ $t('newService.text5') }}
                    </div>
                  </div>
                </div>
                <div class="item-text" v-if="lang != 'en'">
                  {{ $t('newService.text6') }}
                </div>
                <div class="item-text" v-else>
                  {{ $t('newService.text71') }}<br>
                  {{ $t('newService.text72') }}<br>
                  {{ $t('newService.text73') }}
                </div>
              </div>
              <div class="qianxun-item">
                <div class="item-img img3">
                  <div class="item-img__fixedBox">
                    <div class="item-img__fixedBoxText" :class="[lang == 'en' ? 'isEn3' : '']">
                      {{ $t('newService.text7') }}
                    </div>
                  </div>
                </div>
                <div class="item-text" v-if="lang != 'en'">
                  {{ $t('newService.text8') }}
                </div>
                <div class="item-text" v-else>
                  {{ $t('newService.text74') }}<br>
                  {{ $t('newService.text75') }}
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="swiperComponent">
          <swiper :options="swiperOptions" style="width: 1180px;border-radius: 3.125rem;">
            <swiper-slide style="min-width: 1180px;max-width: 1180px;background-color: #fff;height: 23.125rem;border-radius: 3.125rem">
              <div style="display: flex;width: 1180px;">
                <img src="@/assets/img/product_service/black.png" alt=""
                  style="width: 8.1875rem;margin-top: 2.25rem;margin-left: 85px;">
                <div style="text-align: center;font-size: 2.125rem;width: 63.75rem;position: absolute;top: 4rem;left: 6.4375rem;">
                  {{ $t('newService.text9') }}
                </div>
              </div>
              <div style="display: flex;margin-top: 6.25rem;justify-content: center;">
                <img src="@/assets/img/product_service/li.png" alt="" style="width: 6.25rem;border-radius: 50%;">
                <div style="margin-left: 1.875rem;text-align: left;">
                  <div style="margin-top:8px;font-size: 2.125rem;font-weight: 500;color: #00CDCE;">{{ $t('newService.text10') }}</div>
                  <div style="color: #00000080;font-size: 1.5rem;font-weight: 400;">{{ $t('newService.text11') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide style="width: 73.75rem;background-color: #fff;height: 23.125rem;border-radius: 3.125rem">
              <div style="display: flex;">
                <img src="@/assets/img/product_service/black.png" alt=""
                  style="width: 8.1875rem;margin-top: 2.25rem;margin-left: 85px;">
                <div style="text-align: center;font-size: 2.125rem;width: 63.75rem;position: absolute;top: 4rem;left: 6.4375rem;">
                  {{ $t('newService.text12') }}</div>
              </div>
              <div style="display: flex;margin-top: 6.25rem;justify-content: center;">
                <img src="@/assets/img/product_service/zhang.png" alt="" style="width: 6.25rem;border-radius: 50%;">
                <div style="margin-left: 1.875rem;text-align: left;">
                  <div style="margin-top:8px;font-size: 2.125rem;font-weight: 500;color: #00CDCE;">{{ $t('newService.text13') }}</div>
                  <div style="color: #00000080;font-size: 1.5rem;font-weight: 400;">{{ $t('newService.text14') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide style="width: 73.75rem;background-color: #fff;height: 23.125rem;border-radius: 3.125rem">
              <div style="display: flex;">
                <img src="@/assets/img/product_service/black.png" alt=""
                  style="width: 8.1875rem;margin-top: 2.25rem;margin-left: 85px;">
                <div style="text-align: center;font-size: 2.125rem;width: 63.75rem;position: absolute;top: 4rem;left: 6.4375rem;">
                  {{ $t('newService.text15') }}</div>
              </div>
              <div style="display: flex;margin-top: 6.25rem;justify-content: center;">
                <img src="@/assets/img/product_service/wang.png" alt="" style="width: 6.25rem;border-radius: 50%;">
                <div style="margin-left: 1.875rem;text-align: left;">
                  <div style="margin-top:8px;font-size: 2.125rem;font-weight: 500;color: #00CDCE;">{{ $t('newService.text16') }}</div>
                  <div style="color: #00000080;font-size: 1.5rem;font-weight: 400;">{{ $t('newService.text17') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide style="width: 73.75rem;background-color: #fff;height: 23.125rem;border-radius: 3.125rem">
              <div style="display: flex;">
                <img src="@/assets/img/product_service/black.png" alt=""
                  style="width: 8.1875rem;margin-top: 2.25rem;margin-left: 85px;">
                <div style="text-align: center;font-size: 2.125rem;width: 63.75rem;position: absolute;top: 4rem;left: 6.4375rem;">
                  {{ $t('newService.text18') }}</div>
              </div>
              <div style="display: flex;margin-top: 6.25rem;justify-content: center;">
                <img src="@/assets/img/product_service/w.png" alt="" style="width: 6.25rem;border-radius: 50%;">
                <div style="margin-left: 1.875rem;text-align: left;">
                  <div style="margin-top:8px;font-size: 2.125rem;font-weight: 500;color: #00CDCE;">{{ $t('newService.text19') }}</div>
                  <div style="color: #00000080;font-size: 1.5rem;font-weight: 400;">{{ $t('newService.text20') }}</div>
                </div>
              </div>
            </swiper-slide>

          </swiper>
          <div class="swiper-pagination" ></div>
        </div>
        <div class="secondBox">
          <div style="padding-top: 90px;font-size: 2.75rem;font-weight: 500;text-align:center;" v-if="lang != 'en'">{{ $t('newService.text21') }}</div>
          <div style="padding-top: 90px;font-size: 2.75rem;font-weight: 500;text-align:center;" v-else>
            {{ $t('newService.text76') }}<br>
            {{ $t('newService.text77') }}
          </div>
          <div class="qianxun">
            <div style="justify-content: space-evenly;display: flex;">
              <div style="display: inline-block;position: relative;text-align: center;width: 15.25rem">
                <div class="item-img img4">
                  <div class="item-img__fixedBox">
                    <div class="item-img__fixedBoxText" :class="[lang == 'en' ? 'isEn3' : '']">
                      {{ $t('newService.text22') }}
                    </div>
                  </div>
                </div>
                <div
                  style="font-size: 1.375rem;width: 13.625rem;text-align: left;font-weight: 400;padding-left: .625rem;margin-top: 1.25rem;">
                  {{ $t('newService.text23') }}</div>
              </div>
              <div style="display: inline-block;position: relative;text-align: center;width: 15.25rem">
                <div class="item-img img5">
                  <div class="item-img__fixedBox">
                    <div class="item-img__fixedBoxText" :class="[lang == 'en' ? 'isEn2' : '']">
                      {{ $t('newService.text24') }}
                    </div>
                  </div>
                </div>
                <div style="font-size: 1.375rem;width: 16rem;text-align: left;font-weight: 400;margin-top: 1.25rem;">
                  {{ $t('newService.text25') }}</div>
              </div>
              <div style="display: inline-block;position: relative;text-align: center;width: 15.25rem">
                <div class="item-img img6">
                  <div class="item-img__fixedBox">
                    <div class="item-img__fixedBoxText" :class="[lang == 'en' ? 'isEn4' : '']">
                      {{ $t('newService.text26') }}
                    </div>
                  </div>
                </div>
                <div
                  style="font-size: 1.375rem;width: 15.6875rem;text-align: left;font-weight: 400;margin-top: 1.25rem;">
                  {{ $t('newService.text27') }}</div>
              </div>
            </div>

          </div>
        </div>
        <div class="swiperComponent1" style="left: 8.125rem;">
          <swiper :options="swiperOptions1" style="width: 73.75rem;border-radius: 3.125rem;">
            <swiper-slide style="width: 73.75rem;background-color: #fff;height: 23.125rem;border-radius: 3.125rem">
              <div style="display: flex;">
                <img src="@/assets/img/product_service/black.png" alt=""
                  style="width: 8.1875rem;margin-top: 2.25rem;margin-left: 85px;">
                <div style="text-align: center;font-size: 2.125rem;width: 63.75rem;position: absolute;top: 4rem;left: 6.4375rem;">
                  {{ $t('newService.text28') }}</div>
              </div>
              <div style="display: flex;margin-top: 6.25rem;justify-content: center;">
                <img src="@/assets/img/product_service/song.png" alt="" style="width: 6.25rem;border-radius: 50%;">
                <div style="margin-left: 1.875rem;text-align: left;">
                  <div style="margin-top:8px;font-size: 2.125rem;font-weight: 500;color: #00CDCE;">{{ $t('newService.text29') }}</div>
                  <div style="color: #00000080;font-size: 1.5rem;font-weight: 400;">{{ $t('newService.text30') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide style="width: 73.75rem;background-color: #fff;height: 23.125rem;border-radius: 3.125rem">
              <div style="display: flex;">
                <img src="@/assets/img/product_service/black.png" alt=""
                  style="width: 8.1875rem;margin-top: 2.25rem;margin-left: 85px;">
                <div style="text-align: center;font-size: 2.125rem;width: 63.75rem;position: absolute;top: 4rem;left: 6.4375rem;">
                  {{ $t('newService.text31') }}</div>
              </div>
              <div style="display: flex;margin-top: 6.25rem;justify-content: center;">
                <img src="@/assets/img/product_service/fu.png" alt="" style="width: 6.25rem;border-radius: 50%;">
                <div style="margin-left: 1.875rem;text-align: left;">
                  <div style="margin-top:8px;font-size: 2.125rem;font-weight: 500;color: #00CDCE;">{{ $t('newService.text32') }}</div>
                  <div style="color: #00000080;font-size: 1.5rem;font-weight: 400;">{{ $t('newService.text33') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide style="width: 73.75rem;background-color: #fff;height: 23.125rem;border-radius: 3.125rem">
              <div style="display: flex;">
                <img src="@/assets/img/product_service/black.png" alt=""
                  style="width: 8.1875rem;margin-top: 2.25rem;margin-left: 85px;">
                <div style="text-align: center;font-size: 2.125rem;width: 63.75rem;position: absolute;top: 4rem;left: 6.4375rem;">
                  {{ $t('newService.text34') }}</div>
              </div>
              <div style="display: flex;margin-top: 6.25rem;justify-content: center;">
                <img src="@/assets/img/product_service/wangnv.png" alt="" style="width: 6.25rem;border-radius: 50%;">
                <div style="margin-left: 1.875rem;text-align: left;">
                  <div style="margin-top:8px;font-size: 2.125rem;font-weight: 500;color: #00CDCE;">{{ $t('newService.text35') }}</div>
                  <div style="color: #00000080;font-size: 1.5rem;font-weight: 400;">{{ $t('newService.text36') }}</div>
                </div>
              </div>
            </swiper-slide>
            <swiper-slide style="width: 73.75rem;background-color: #fff;height: 23.125rem;border-radius: 3.125rem">
              <div style="display: flex;">
                <img src="@/assets/img/product_service/black.png" alt=""
                  style="width: 8.1875rem;margin-top: 2.25rem;margin-left: 85px;">
                <div style="text-align: center;font-size: 2.125rem;width: 63.75rem;position: absolute;top: 4rem;left: 6.4375rem;">
                  {{ $t('newService.text37') }}</div>
              </div>
              <div style="display: flex;margin-top: 6.25rem;justify-content: center;">
                <img src="@/assets/img/product_service/xia.png" alt="" style="width: 6.25rem;border-radius: 50%;">
                <div style="margin-left: 1.875rem;text-align: left;">
                  <div style="margin-top:8px;font-size: 2.125rem;font-weight: 500;color: #00CDCE;">{{ $t('newService.text38') }}</div>
                  <div style="color: #00000080;font-size: 1.5rem;font-weight: 400;">{{ $t('newService.text39') }}</div>
                </div>
              </div>
            </swiper-slide>

          </swiper>
          <div class="swiper-pagination swiper-pagination1" ></div>
        </div>
        <div class="bottomBox"
          style="">
          <div style="font-size: 2.75rem;font-weight: 500;padding-top: 90px;text-align:center;margin-bottom: 4.375rem">{{ $t('newService.text40') }}</div>
          <div style="display: flex;justify-content: space-evenly">
            <div style="width: 33rem;height: 14.375rem;background: #F7F7F7;border-radius: 2.5rem;display: flex;justify-content: space-between;">
              <img src="@/assets/img/product_service/shou.png" alt="" style="width: 11.25rem;">
              <div style="padding-top: 1.25rem;">
                <div class="item-title" :class="[lang == 'en' ? 'isEn1' : '']">
                  <div class="title-text">
                    {{ $t('newService.text41') }}
                  </div>
                </div>
                <div class="item-content">
                  <div class="content-text" :class="[lang == 'en' ? 'isEnContent1' : '', (lang == 'en' && onHover && onHoverIndex == 1 ? 'isEnContent' : '')]">
                    <div class="content-text1" v-if="lang == 'en'">
                      {{ $t('newService.text55') }}<br>
                      {{ $t('newService.text56') }}<br>
                      {{ $t('newService.text57') }}<br>
                      {{ $t('newService.text58') }}
                    </div>
                    <div class="content-text1" v-else>
                      {{ $t('newService.text42') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 33rem;height: 14.375rem;background: #F7F7F7;border-radius: 2.5rem;display: flex;justify-content: space-between;">
              <img src="@/assets/img/product_service/you.png" alt="" style="width: 11.25rem;">
              <div style="padding-top: 1.25rem;">
                <div class="item-title" :class="[lang == 'en' ? 'isEn1' : '']">
                  <div class="title-text">
                    {{ $t('newService.text43') }}
                  </div>
                </div>
                <div class="item-content">
                  <div class="content-text" :class="[lang == 'en' ? 'isEnContent1' : '', (lang == 'en' && onHover && onHoverIndex == 2 ? 'isEnContent' : '')]">
                    <div class="content-text1" v-if="lang == 'en'">
                      {{ $t('newService.text59') }}<br>
                      {{ $t('newService.text60') }}<br>
                      {{ $t('newService.text61') }}<br>
                      {{ $t('newService.text62') }}
                    </div>
                    <div class="content-text1" v-else>
                      {{ $t('newService.text44') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex;justify-content: space-evenly;margin-top: 2.5rem">
            <div style="width: 33rem;height: 14.375rem;background: #F7F7F7;border-radius: 2.5rem;display: flex;justify-content: space-between;">
              <img src="@/assets/img/product_service/ling.png" alt="" style="width: 11.25rem;">
              <div style="padding-top: 1.25rem;">
                <div class="item-title" :class="[lang == 'en' ? 'isEn1' : '']">
                  <div class="title-text">
                    {{ $t('newService.text45') }}
                  </div>
                </div>
                <div class="item-content">
                  <div class="content-text" :class="[lang == 'en' ? 'isEnContent1' : '', (lang == 'en' && onHover && onHoverIndex == 3 ? 'isEnContent' : '')]">
                    <div class="content-text1" v-if="lang == 'en'">
                      {{ $t('newService.text63') }}<br>
                      {{ $t('newService.text64') }}<br>
                      {{ $t('newService.text65') }}
                    </div>
                    <div class="content-text1" v-else>
                      {{ $t('newService.text46') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div style="width: 33rem;height: 14.375rem;background: #F7F7F7;border-radius: 2.5rem;display: flex;justify-content: space-between;">
              <img src="@/assets/img/product_service/feng.png" alt="" style="width: 11.25rem;">
              <div style="padding-top: 1.25rem;">
                <div class="item-title" :class="[lang == 'en' ? 'isEn1' : '']">
                  <div class="title-text">
                    {{ $t('newService.text47') }}
                  </div>
                </div>
                <div class="item-content">
                  <div class="content-text" :class="[lang == 'en' ? 'isEnContent1' : '', (lang == 'en' && onHover && onHoverIndex == 4 ? 'isEnContent' : '')]">
                    <div class="content-text1" v-if="lang == 'en'">
                      {{ $t('newService.text66') }}<br>
                      {{ $t('newService.text67') }}<br>
                      {{ $t('newService.text68') }}
                    </div>
                    <div class="content-text1" v-else>
                      {{ $t('newService.text48') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

    </div>
    <MobileBottom class="mobileBottom"></MobileBottom>
  </div>
</template>
<script>
import MobileBottom from './components/MobileBottom.vue'
import 'swiper/css/swiper.css'
export default {
  name: 'ServicePage',
  components: {
    MobileBottom
  },
  data () {
    return {
      swiperOptions: {
        direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical
        slidesPerView: 1, // Swiper的列数
        // spaceBetween: 30, //板块间距
        // 设置分页器
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets', // 将分页的类型改为圆点（默认)
          clickable: true // 点击可切换
          // hideOnClick :true, // 点击swiper-slide显隐
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 2秒切换一次
          disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        loop: true, // 无缝轮播
        grabCursor: true // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
      },
      swiperOptions1: {
        direction: 'horizontal', // Swiper的滑动方向,可设置为水平方向切换 horizontal（默认） 或垂直方向切换 vertical
        slidesPerView: 1, // Swiper的列数
        // spaceBetween: 30, //板块间距
        // 设置分页器
        pagination: {
          el: '.swiper-pagination1',
          type: 'bullets', // 将分页的类型改为圆点（默认)
          clickable: true // 点击可切换
          // hideOnClick :true, // 点击swiper-slide显隐
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        // 设置自动轮播
        autoplay: {
          delay: 3000, // 2秒切换一次
          disableOnInteraction: false // 用户操作swiper之后，是否禁止autoplay。默认为true：停止。
        },
        loop: true, // 无缝轮播
        grabCursor: true // 覆盖Swiper 时指针会变成手掌形状，拖动时指针会变成抓手形状
      },
      lang: '',
      onHover: false,
      onHoverIndex: 0
    }
  },
  created () {
    const that = this
    that.lang = window.localStorage.getItem('language') || 'tw'
    that.$EventBus.$on('refreshservicelang', () => {
      that.lang = window.localStorage.getItem('language') || 'tw'
    })
  },
  mounted () {
    window.scrollTo({ top: 0 })
    this.$nextTick(() => {
      if (this.$refs.swiper) {
        this.$refs.swiper.swiper.update() // 触发更新
      }
    })
  },
  methods: {
    test1 (index) {
      const that = this
      that.onHoverIndex = index
      that.onHover = true
      console.log('111')
    },
    test2 () {
      const that = this
      that.onHover = false
      console.log('222')
    }
  }
}
</script>

<style src="@/assets/style/ServicePage.scss" lang="scss"></style>
