/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './locales/i18n'
// import '../rem.js'
import VueAwesomeSwiper from 'vue-awesome-swiper'
import css from 'swiper/css/swiper.css';

Vue.config.productionTip = false

Vue.prototype.$EventBus = new Vue()

Vue.use(VueAwesomeSwiper, {
  css
})

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')

