export default {
  header: {
    tagName: '千循',
    homepage: '首页',
    service: '产品与服务',
    qianxunAPP: '千循APP',
    SaaSFenhao: '分毫云SaaS',
    qianxunPremium: '千循优选',
    about: '关于我们',
    aboutQianxun: '关于千循',
    developmentHistory: '发展历程',
    relationship: '投资者关系',
    companyInfo: '公司概括',
    financialReport: '财务报告',
    temporaryNotice: '临时公告',
    regularNotice: '定期公告',
    news: '企业新闻',
    qianxunInformation: '千循资讯',
    qianxun: '千循资讯',
    industryTrends: '行业动态',
    industry: '行业动态',
    contact: '联系我们',
    address: '公司地址：深圳市福田区梅林街道中康路136号深圳新一代产业园1栋6层',
    allRightsReserved: '版权所有 © 2025 千循科技有限公司',
    seeMore: '查看更多',
    companyName: '千循科技',
    companyReport: '公司公告'
  },
  content: {
    image: {
      banner: require('@/assets/banner_zh.png'),
      aboutUs: require('@/assets/aboutUs_zh.png'),

      homeProductText: require('@/assets/img/home/service_and_product_zh.png'),
      homeQianxunStore: require('@/assets/img/home/qianxun_store_zh.png'),
      homeSaaS: require('@/assets/img/home/SaaS_zh.png'),
      homeGoods: require('@/assets/img/home/goods_zh.png'),
      about: require('@/assets/img/about/aboutqianxun_zh.png'),
      aboutContent: require('@/assets/img/about/aboutIntroduction_zh.png'),
      aboutThree: require('@/assets/img/about/three_zh.png'),
      aboutMap: require('@/assets/img/about/map_zh.png'),
      aboutHistory: require('@/assets/img/about/history_zh.png'),
      about3C: require('@/assets/img/about/3C_zh.png'),
      news: require('@/assets/news.png'),
      relationship: require('@/assets/relationship.png'),
      seeMore: require('@/assets/seeMore_zh.png'),
      phone: require('@/assets/phone.png')
    },
    homepageText: {
      viewDetails: '查看详情',
      viewMore: '查看更多',
      learnMore: '了解更多',
      financialReport: '财务报告',
      aboutMe: {
        head: '关于我们',
        headContext: '打造首个开放的二手3C交易供应链',
        subText: '在我们的二手电商平台，闲置好物开启全新旅程，焕发出别样光彩。平台汇聚了海量的精选商品，其中以二手 3C数码产品尤为瞩目。从尖端智能手机到高性能电脑，从专业摄影器材到各类智能穿戴设备，丰富的 3C 数码品类一应俱全，满足您对科技产品的多元需求。',
        firstItemTitle: '精准估价 高效回收',
        secondItemTitle: '及时匹配 快速去化',
        thirdItemTitle: '无忧交付 树立标准',
        firstItemTips: 'AI驱动的实时定价系统，完善的在线检测评估系统。精准估价，打款便捷快速。精准的投流模型，高效触达，回收机主，有效获取二手机货盘。',
        secondItemTips: '只需用户提出需求，后续选品、议价、下单等繁琐环节均由我们包办，节省您的时间与精力。依托平台大数据，实时掌握市场动态，确保采购价格优势。',
        thirdItemTips: '二手商品品质看得见，现货陈列，实物细节尽在眼前，您可多角度审视，购物更安心。库存实时更新，所见即所得，杜绝下单与实物不符的尴尬。'
      }
    },
    relationshipPage: {
      temporaryNotice: '临时公告',
      regularNotice: '定期公告',
      clickToDownload: '点击下载'
    }
  },
  newHome: {
    text1: '让科技永续，让价值循环',
    text2: '构建全球领先的二手3C循环生态',
    text3: '香港上市企业(股票代码：1640.HK)',
    text4: '千循科技以AI技术与SaaS平台为驱动，打造“线上交易+智能管理+线下服务”的全链路解决方案，让每一台二手3C设备焕发新生。',
    text5: '吨',
    text6: '每年减少电子垃圾',
    text7: '设备再利用率',
    text8: '万吨',
    text9: '二氧化碳减排量',
    text10: '立即估价',
    text11: '为什么选择千循？',
    text12: '72小时，从闲置到价值重生',
    text13: '通过AI智能定价与分毫云供应链网络，千循实现行业内最快的流转效率，用户提交设备后72小时内完成质检、上架、销售全流程，平均动销率提升40%。',
    text14: '立足中国，服务全球',
    text15: '千循设立了香港供应链中心、北美迈阿密与中东迪拜运营中心协同布局，覆盖全球超过40家国际电信运营商和品牌商，为全球用户提供更高性价比的二手3C产品。',
    text16: '128项检测，180天无忧质保，7天无理由退换',
    text17: '每台设备经128项严苛检测，涵盖硬件、数据、性能等11道工序，品质有保障；180天无优质保，安心使用；7天无理由退换，购物无忧。',
    text18: '千循不错，比卖给我朋友多赚了500块！',
    text19: '张先生',
    text20: '北京用户',
    text21: '库存周转从45天缩短到19天。',
    text22: '李女士',
    text23: '某手机数码工作室',
    text24: '代采功能让我一键比价华强北，采购成本降低8%，还能锁定区域独家货源。',
    text25: '王先生',
    text26: '安徽数码经销商',
    text27: '太方便了，以旧换新还能直接去线下千循优选取货，不用等快递就是爽。',
    text28: '谢先生',
    text29: '江西用户',
    text30: '合作伙伴',
    text31: '下载千循APP，获取更高估价'
  },
  newService: {
    text1: '以AI技术和SaaS平台赋能二手3C交易',
    text2: '千循APP：您的闲置，从这里开始新的旅程',
    text3: '智能回收',
    text4: 'AI秒级估价，顺丰免费上门取件',
    text5: '严选商城',
    text6: '128项质检认证，180天质保，7天无理由退换',
    text7: '以旧换新',
    text8: '旧机最高抵3000元，支持跨品牌置换',
    text9: '旧iPhone 12回收价2150元，加799元换购iPhone 15!',
    text10: '李女士',
    text11: '北京用户',
    text12: '用千循卖掉5D4，比本地二手商多赚2200！AI验机功能太实用了，镜头微痕都拍得清清楚楚。',
    text13: '张先生',
    text14: '杭州用户',
    text15: '通过APP批量代采翻新机，采购价比华强北批发市场还低8%，质保期延长到180天。',
    text16: '王先生',
    text17: '成都数码店主',
    text18: '以旧换新计划太棒了！旧华为Mate40抵扣了2850元，新机分期免息，月供才399元。',
    text19: '王先生',
    text20: '深圳用户',
    text21: '分毫云SaaS：二手3C商家的智能生意管家',
    text22: 'AI动销助手',
    text23: '实时监控各渠道价格波动，自动生成调价策略，提升动销确保利润率。',
    text24: '供应链管家',
    text25: '集成ERP智能管理和全渠道销售中台，自动化订单处理、多仓智能调拨、动态库存预测。',
    text26: '全渠道同售',
    text27: '接入主流销售平台并支持三合一小程序，一键拓展线上销售渠道。',
    text28: '接入分毫云后，滞销库存从37%降到9%，动销率提升至行业TOP5%，月均GMV突破100万。',
    text29: '宋先生',
    text30: '深圳某数码工作室',
    text31: '利用AI调价策略，iPhone系列利润率提高11%，库存周转天数从38天压缩到15天。',
    text32: '付先生',
    text33: '武汉手机卖家',
    text34: '三合一小程序直接对接直播间，上新速度提升3倍，客诉率下降65%。',
    text35: '王女士',
    text36: '杭州二手机直播卖家',
    text37: '质量追溯系统帮我们定位到批次电池问题，避免潜在售后损失超80万元。',
    text38: '夏先生',
    text39: '成都维修服务商',
    text40: '千循优选运营中心：二手3C标准化体验中心',
    text41: '全面的售后保障与专业认证',
    text42: '确保客户购机无忧，提供7天无理由退换货、180天质保和终身技术支持，同时提供详细的检测报告。',
    text43: '优化的交易流程与资源整合',
    text44: '通过整合区域二手货源和搭建B端交易平台，提升交易透明度与信任度，同时降低采购成本。',
    text45: '灵活的购买方案与透明定价',
    text46: '提供以旧换新服务和实时市场化价格，激发购买意愿并保障购买安心。',
    text47: '丰富的商品选择与快速交付',
    text48: '提供近千款SKU，涵盖多种类型，并确保快速面对面履约，履约时效领先同行。',
    text49: '千循科技(1640.HK)作为领先的二手数码交易服务商，致力于以AI技术和SaaS平台赋能二手数码交易，满足消费降级周期下的巨大市场需求。公司通过线上平台回收、渠道自助机回收、自营门店回收等多种方式，实现二手3C产品的高效回收。同时，借助AI技术和SaaS平台，为二手数码交易提供精准报价、智能同售、库存管理等一站式服务，提升交易效率与利润空间。',
    text50: '千循科技凭借其创新的业务模式和强大的技术实力，已在香港、深圳、迈阿密、迪拜等地设立运营中心，形成了全球化的业务布局。公司旗下千循APP拥有超过1000万注册用户，日均订单量超1000+，成为用户买卖二手数码产品的首选平台。此外，千循优选城市运营中心的建立，进一步整合了区域二手货盘，打破了信息孤岛，提升了商品流转效率。',
    text51: '展望未来，千循科技将继续加大在技术研发、市场拓展、品牌建设等方面的投入，进一步巩固其在二手数码交易领域的领先地位，为股东创造更大的价值。',
    text52: '千循科技(1640.HK)作为领先的二手数码交易服务商，致力于以AI技术和SaaS平台赋能二手数码交易，满足消费降级周期下的巨大市场需求。',
    text53: '公司通过线上平台回收、渠道自助机回收、自营门店回收等多种方式，实现二手3C产品的高效回收。',
    text54: '同时，借助AI技术和SaaS平台，为二手数码交易提供精准报价、智能同售、库存管理等一站式服务，提升交易效率与利润空间。',
    text55: '以AI技术和SaaS平台赋能',
    text56: '二手3C交易'
  },
  newAboutMe: {
    text1: '关于千循',
    text2: '千循科技致力于构建全场景数码产品循环生态，通过线上平台+SaaS工具+线下服务三位一体的解决方案，为用户提供便捷、可靠的二手3C产品交易及管理服务，推动绿色循环经济发展。',
    text3: '核心业务布局',
    text4: '数字交易平台',
    text5: '千循APP集成商城、代采、回收三大功能，专注手机及周边数码产品流通。平台凭借智能推荐系统与精准搜索技术，让用户轻松实现闲置变现与优质购机体验。',
    text6: '智慧SaaS系统',
    text7: '分毫云为企业客户提供数字化管理解决方案，集成ERP智能管理、三合一小程序、全渠道销售中台等功能。系统支持自动化订单处理、多仓智能调拨、动态库存预测及全链路质量追溯，助力企业降本增效。',
    text8: '实体服务网络',
    text9: '千循优选运营中心遍布全国核心城市，打造检测-回收-销售-服务四位一体实体站点，提供专业设备回收、官方认证产品销售、售后维修及以旧换新等一站式服务。',
    text10: '品质保障体系',
    text11: '128项严苛质检',
    text12: '每件3C产品均需通过128项严苛检测，涵盖硬件、性能、数据清理等11道工序，由百人专业质检团队执行，确保设备达到准新机标准。',
    text13: '180天无忧质保',
    text14: '提供180天无忧质保服务，质保期内设备出现故障，我们将提供免费维修或更换，售后团队快速响应，保障您的使用体验。',
    text15: '7天无理由退换',
    text16: '支持7天无理由退换服务，不满意可随时申请，客服团队全程协助，确保退换流程便捷高效，让您购物无后顾之忧。',
    text17: '可持续未来的承诺',
    text18: '作为循环经济践行者，我们已实现：',
    text19: '【减少电子垃圾4618吨】 【产品再利用率达92.3%】 【二氧化碳减排量210万吨】',
    text20: '通过技术创新与服务升级，我们持续推动让每台设备完成至少3次生命周期的愿景，为用户创造价值的同时守护绿色地球。',
    text21: '公司成立',
    text22: '3C报价服务平台“分毫报价”上线',
    text23: 'APP上线',
    text24: '千循APPP上线',
    text25: '推出分毫云SaaS',
    text26: '行业领先的3C全品类回收估价系统',
    text27: '短视频平台流量矩阵搭建',
    text28: 'APP用户量突破1000万+',
    text29: '内容矩阵粉丝2000万+',
    text30: '曝光覆盖人数1亿+',
    text31: 'APP领跑二手赛道',
    text32: '平台618大促二手数码交易量全网前三',
    text33: '完成香港上市重组',
    text34: '港股“循环经济”第一股',
    text35: '公司发展历程'
  }
}
