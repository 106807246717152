<template>
    <div class="detailPage">
        <div>
            <div class="detail-header">
                <img :src="$t('content.image.news')" alt="" style="width: 100%;">
            </div>
            <div class="detail-content" >
                <div class="content-title">{{ detailTitle }}</div>
                <div style="display: flex;align-items: center;margin-bottom: 2.625rem;">
                  <div class="content-tag">{{ type === 'info' ? $t('header.qianxun') : $t('header.industry') }}</div>
                  <div class="content-line">|</div>
                  <div class="content-date">{{ date }}</div>
                </div>
                <div class="clearfix">
<!--                  <view :node="htmlContent"></view>-->
                  <div v-html="htmlContent" style="margin-bottom: 5.625rem;"></div>
                </div>
            </div>
        </div>
        <MobileBottom class="mobileBottom"></MobileBottom>
    </div>
</template>
<script>
import MobileBottom from './MobileBottom.vue'
import { getDetail } from '@/api/list'
export default {
  name: 'DetailPage',
  components: {
    MobileBottom
  },
  data () {
    return {
      htmlContent: undefined,
      mountedContent: undefined,
      detailTitle: undefined,
      itemId: undefined,
      type: undefined,
      date: undefined,
      tagList: []
    }
  },
  async created () {
    this.itemId = this.$route.query.id
    const res = await getDetail(this.itemId)
    this.mountedContent = res.result.content
    this.htmlContent = this.mountedContent.replace(/\n/g, '<br />')
    this.detailTitle = res.result.title
    this.type = res.result.type
    this.date = res.result.infoDateStr
    this.tagList = res.result.tagList
    console.log('res', res.result)
    console.log('tagList', this.tagList)
  }
}
</script>

<style scoped lang="scss">
.clearfix::after {
    content: "";
    display: block;
    clear: both;
  }
.detailPage {
    width: 100%;
    margin: 0 auto;
}

.detail-content {
    padding-right: 7.5rem;
    padding-left: 7.5rem;
    padding-top: 4.3125rem;

    .content-title {
        font-size: 2.875rem;
        font-weight: bold;
        margin-bottom: 2.25rem;
    }

    .content-tag {
        font-size: 1.5rem;
        color: #8C8C8C;
    }

    .content-line {
        color: #C1C1C1;
        height: 1.375rem;
        margin-right: 1.25rem;
        margin-left: 1.25rem;
    }

    .content-date {
        font-size: 1.5rem;
        color: #8C8C8C;
    }

    .content-txt {
        margin-top: 2.625rem;
        font-size: 1.75rem;
    }
}

@media (max-width: 768px) {}
</style>
